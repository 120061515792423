
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import DocumentTable from '@/components/document/document-table.cmp.vue'
import { Document } from '@/api/interfaces/documents'
import { usePagination } from '@/utils/pagination'
import { documentApi } from '@/api'
import { useAuthGetters } from '@/store'
import { mdiPlus } from '@mdi/js'

export default defineComponent({
  name: 'user-documents',
  components: {
    DocumentTable,
  },
  setup() {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.multiSort = true
    vuetifyTableOptions.value.sortBy = ['year', 'month']
    vuetifyTableOptions.value.sortDesc = [true, true]

    const items = ref<Document[]>([])
    const serverItemsLength = ref(0)
    const loading = ref(false)
    const years = computed(() => {
      const now = new Date().getUTCFullYear()
      return Array(now - (now - 3))
        .fill('')
        .map((v, idx) => now - idx) as Array<number>
    })
    const { currentUser } = useAuthGetters()
    const filters = reactive({
      userId: currentUser.value.id,
      year: null,
    })

    async function getDocuments() {
      loading.value = true
      documentApi.all({ params: { ...paginationParams.value, ...filters } }).then((response) => {
        items.value = response?.items || []
        serverItemsLength.value = response?.data.totalElements
        loading.value = false
      })
    }

    watch(filters, () => {
      getDocuments()
    })

    return {
      items,
      serverItemsLength,
      loading,
      years,
      getDocuments,
      vuetifyTableOptions,
      filters,
      icons: { mdiPlus },
    }
  },
})
