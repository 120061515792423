
import { documentApi } from '@/api'
import { useGetUsers } from '@/api/users'
import { defineComponent, onMounted } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiBriefcaseDownload, mdiDelete } from '@mdi/js'
import { Document } from '@/api/interfaces/documents'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'

export default defineComponent({
  name: 'document-table',
  inheritAttrs: false,
  props: {
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const headers: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
      },
      {
        text: root.$t('form.field.year').toString(),
        value: 'year',
      },
      {
        text: root.$t('form.field.month').toString(),
        value: 'month',
      },
      {
        text: root.$t('form.field.user').toString(),
        value: 'user',
      },
      {
        text: root.$t('form.field.filename').toString(),
        value: 'name',
      },
      {
        text: root.$t('form.field.size').toString(),
        value: 'size',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ]

    const { getUsers, data: users } = useGetUsers()
    const { hasRight, currentUser } = useAuthGetters()

    function getUserStringByUserId(userId: number) {
      const user = users.value.find((user) => user.id === userId)
      return `${user?.firstName} ${user?.lastName}`
    }

    function getSizeAsString(bytes: number) {
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const sizes = ['Bytes', 'KB', 'MB', 'GB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    function downloadDocument(item: Document) {
      documentApi.downloadFile(item.id, item.name)
    }

    onMounted(() => {
      if (hasRight.value(Rights.USER_READ)) getUsers({ page: 0, size: 2000 })
      else users.value = [currentUser.value]
    })

    return {
      headers,
      getUserStringByUserId,
      getSizeAsString,
      downloadDocument,
      icons: { mdiBriefcaseDownload, mdiDelete },
    }
  },
})
